
/******** INITIAL CSS **********/

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&family=Raleway:wght@100;400&display=swap');


body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #f1f2f2 !important;
  -moz-osx-font-smoothing: grayscale;
}

/******** INITIAL CSS **********/

/********* NAVBAR && FOOTER *********/

.pages {
  color: #316685;
  text-align: center;
  font-size: calc(1.5rem + 2vw);
  margin-top: 10%;
}

.navbar {
  background-color: #7b3246;
  height: 65px;
  display: flex;
  padding: 0px;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 99;
  font-weight: 400;
}
.fa-map-marker{
  color: #967a50;
}
.main-header{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 13px;
}
.nav-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.room-bg{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width: 100%;
}
.priceslist{
  font-family: sans-serif !important;
  font-size: 17px !important;
}
.myreservations{
  font-size: 38px;
  font-weight: 400;
  color: white;
}
.fees{
  font-family: sans-serif !important;
}
.footer p {
  margin-bottom: 0px;
  margin: 10px;
  font-weight: 400;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #7b3246;
  color: white;
  text-align: center;
}
.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.nav-links-last {
  padding: 0px !important;
}
.nav-links:hover {
  color: white;
}
.last-item {
  border-left: 1px solid white;
  height: 100%;
  text-align: center;
  padding: 10px;
  color: white;
}
.circle {
  width: 100px;
  height: 100px;
  background: red;
  border-radius: 50%;
}
.nav-logo {
  width: 120px;
  color: white;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: -28px;
}
.logo {
  height: 120px;
}
.nav-menu {
  height: 100%;
  margin-bottom: 0px;
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: space-between;
  width: 100%;
}
.border-lng {
  margin-left: auto;
  margin-right: auto;
}
.indicator-arrow {
  width: 0;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fff;
  position: absolute;
  bottom: 0;
}
.nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
}
.border-lng {
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  color: #d2d2d2;
  height: 24px;
  width: 24px;
}
.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  align-self: center;
  line-height: 40px;
}

.menu-items{
  width: 35%;
}

.nav-item:hover:after {
  width: 100%;
  background: white;
}

.nav-item .active {
  color: white;
  border-bottom: 1px solid white !important;
}
.iIlfol{
  background: #7b3246 !important;
}
.nav-icon {
  display: none;
}
.App {
  padding-top: 10vh;
  padding-bottom: 10vh;
  min-height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginApp {
  min-height: 83vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signupApp{
  width: 40%;
  margin: auto;
}
.signup-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}
.SignupSuccess{
  font-weight: 400;
  color: #7b3246;
}
.SignupSuccess h1{
  font-weight: 500;
  font-family: 'Playfair Display', serif;
  color: #7b3246;
}
.SignupSuccess h3{
  font-weight: 500;
  font-family: 'Playfair Display', serif;
  color: #7b3246;
  font-size: 25px;
}
.app-field-message {
  padding: 10px 0 5px;
  font-size: 10px;
  line-height: 1.5;
  font-weight: 500;
  color: #707070;
}
.country-input{
  margin-top: 4px;
  border: none;
  border-bottom: 1px solid #242424;
  border-radius: unset;
}
.menu-flags ul{
  background: #f1f2f2;
  font-weight: 400;
}
.menu-flags div{
  background: #f1f2f2;
}
.menu-flags input{
  background: #f1f2f2;
}
.signupApp input{
  height: 30px;
}
.terms-and-conditions{
  padding: 10px 0 5px;
  font-size: 10px;
  line-height: 1.5;
  font-weight: 500;
  color: #707070;
}

.PhoneInputInput{
  background: transparent;
  border: none;
  border-bottom: 1px solid #242424;
}

/********* NAVBAR && FOOTER *********/

.card-bookingreservation{
  background-color: #7b3246;
  padding:15px;
  height: 100%;
}
.cardreservation{
  background: #967a50;
}
.card-bookingreservation select{
    padding: 6px 12px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d2d2d2;
    height: 48px;
    font-size: 1rem;
    color: #444;
    box-shadow: none;
    border-radius: 0;
}
.reservation-wrapper{
  height: 100%;
    padding: 7px;
    border: 1px solid #967a50;
}
.reservation-wrapper-2{
  height: 100%;
  padding: 7px;
  border: 1px solid #7b3246;
}
.reservation-wrapper-2 button{
  background: white;
  margin-top: 15px;
  margin-left: 0px !important;
}
.card-bookingreservation button{
  background: white;
}
.Backoffice h1{
  font-size: 20px;
  font-weight: 600;
}
.Backoffice input{
  height: 40px;
}
.Backoffice select{
  height: 40px;
}
.note-resa{
  color: white;
  font-weight: 500;
  font-size: 14px;
}
.reservation-detail tr td{
  padding: 10px;
}
.reservation-detail tr{
  padding: 10px;
  border-bottom: 1px solid #967a50;
}
.table-title{
  font-weight: 600;
  font-size: 13px;
  margin-left: 5px;
  margin-bottom: 2px;
  font-family: 'Roboto';
}
.table-description{
  font-weight: 400;
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 0px;
  font-family: 'Roboto';
}
.btns-reservationdetails{
  float:right;
}
.Backoffice button{
  height: 40px;
}
.Backoffice label{
  color: white;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}
.card-bookingreservation div button{
  margin-left: 20px !important;
}
.Backoffice{
  padding-top: 100px;
  padding-bottom: 50px;
}

/********* MAIN PAGE *********/
.title {
  font-weight: 500;
  margin: 0 0 0px;
  text-align: center;
  color: #7b3246;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  color: #7b3246;
  font-family: 'Playfair Display', serif;
}
.arrow-up {
  width: 0;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #fff;
}
.dateField {
  border-color: #ffffff;
  min-height: 20vh;
}
.dateField {
  background-color: #ffffff;
}
.dateField {
  background: none repeat scroll 0 0 #fff;
  color: #2f373b;
  float: left;
  padding: 16px 0 26px;
  position: relative;
  width: 50%;
  border: 2px solid transparent;
}
.dateField p.month {
  border-bottom: 1px solid #f1f2f2;
  color: #967a50;
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 10px;
}
#datePicker .dateField p,
#datePicker .ui-state-default,
#datePicker .ui-widget-content {
  color: #2f373b;
}
.dateField p {
  color: #2f373b;
  text-align: center;
}
.dateField p.day {
  font-size: 3.5rem;
  font-weight: 300 !important;
  line-height: 72px;
  margin: 0;
  padding-bottom: 18px;
  padding-top: 14px;
}

.dateField p.day,
.ui-datepicker-year {
  font-family: Lato, sans-serif;
}
.dateField .border {
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  color: #d2d2d2;
  height: 24px;
  width: 24px;
  position: absolute;
  bottom: 15px;
  margin: auto;
  left: 45%;
}
.datefield-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.datef p.month {
  border-bottom: 1px solid #f1f2f2;
  color: #967a50;
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 10px;
}
.datef .border {
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  color: #d2d2d2;
  height: 24px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  position: relative;
    bottom: 15px;
}
.dateField .border {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}
.arrow {
  background: url("./img/arrow.png") no-repeat scroll 50% bottom transparent;
  display: block;
  height: 6px !important;
  margin-left: 5.5px !important;
  margin-top: 9px !important;
  position: relative;
  width: 10px !important;
}
.button-stepper {
  text-transform: uppercase;
  background-color: #f1f2f2 !important;
  border: 2px solid #907650;
  color: #907650;
  padding: 7px 20px;
  font-size: 16px;
  letter-spacing: 2px;
  border-radius: 5px;
}
.button-stepper:hover {
  text-transform: uppercase;
  background-color: #907650 !important;
  border-color: #907650;
  color: white;
  padding: 7px 20px;
  font-size: 16px;
  letter-spacing: 2px;
  border-radius: 5px;
}
.reservation_header {
  color: #031337;
}
.reservation_header,
.reservation_text {
  font-weight: 500;
  font-size: 0.125rem;
  letter-spacing: 0.1rem;
  display: block;
  text-align: right;
  line-height: 1.8rem;
}
.reservation_text,
.reservation_hint {
  color: #5b5b5b;
}
.booking-button_wrapper {
  text-align: center;
  margin-top: 15px;
}
.reservation_text {
  font-family: Lato, sans-serif;
  color: #999;
}
.reservation_header,
.reservation_text {
  color: #031337;
  font-weight: 500;
  font-size: 1.125rem;
  letter-spacing: 0.1rem;
  display: block;
  text-align: right;
  line-height: 1.8rem;
}
.reservation_text,
.reservation_hint {
  color: #5b5b5b;
}
.reservation_hint {
  font-size: 0.875rem;
  font-weight: 400;
  color: #5b5b5b;
  line-height: 1.8rem;
  display: block;
  text-align: right;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 90px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: -1;
}
.vis-datepicker {
  position: relative;
  bottom: 25px;
  visibility: hidden;
}
/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}
.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* The container <div> - needed to position the dropdown content */
.dropdown-rooms {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content-rooms {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 100%;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content-rooms a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}
.dropdown-content-rooms a:hover {
  background-color: #ddd;
}

.dropdown-rooms:hover .dropdown-content-rooms {
  display: block;
}

@media screen and (max-width: 960px) {
  .current-room{
    position: unset !important;
  }
  .modal-rooms{
    padding: 0px !important;
    flex-direction: column !important;
  }
  .notes-list-hotel{
    list-style: none !important;
  }
  .reservation_wrapper{
    margin-bottom: 20px;
  }
  .logo {
    height: 80px;
  }
  .nav-links{
    display: none !important;
  }
  .nav-menu {
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 0px;
    left: 0%; 
    opacity: 1;
    transition: all 0.5s ease;
  }
  .Backoffice{
    padding-top: 20px;
  }
  .form-edit-user form{
    width: 100% !important;
  }
  .main-header{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .btn_bepartner{
    position: relative !important;
  }
  .login-row {
    padding: 40px 10px 10px 10px !important;
  }
  .border-l {
     border-right: none !important; 
  }
  .login-row{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: start !important;
  }
  .loginicons{
    flex-wrap: wrap;
  }
  .signupApp{
    width: 100% !important;
  }
  .card-form-payment{
    flex-direction: column !important;
  }
  .main-header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 13px;
    flex-wrap: wrap;
    justify-content: center;
    padding: -1px;
}
  .modal-markup .modal-content{
    padding: 0px !important;
  }
  .modal-markup .modal-body{
    padding-right: 0px !important;
  }
  .notes-list{
    margin-left: 0px !important;
  }
   .rate__room--listing span{
     font-size: 10px;
   }
  .rate__content{
    margin-left: 0px !important;
  }
  .sidebar{
     width: 100% !important;
  }
  .card-notes{
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px;
  }
  .card-rooms{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .footer p{
    font-size: 9px;
  }
  .App {
    padding-top: 10px;
  }
  .main-page{
    position: relative;
  }
  .dropdown-guest {
    padding: 20px;
    position: fixed !important;
    top: 0px !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 9999 !important;;
    height: 100% !important;;
  }
  .li-guest {
    padding: 8px 12px !important;
}
.addrow{
  margin-top: 10px !important;
}
  .ul-guest{
    display: block !important;
  }
  .reservation_header{
    font-size: 15px;
  }
  .guests-mb{
    margin-top: 15px;
    position: unset !important;
  }
  .dateField{
    min-height: 150px;
  }
  .datef{
    min-height: 150px !important; 
    

  }
  .title {
    font-size: 1rem;
    margin-top: 10px;
  }

  .booking-button_wrapper {
    margin-top: 10px;
  }

  .dateField p.day {
    font-size: 2.375rem;
    font-weight: 300 !important;
    line-height: 20px;
    margin: 0;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .button-stepper {
    text-transform: uppercase;
    background-color: #f1f2f2 !important;
    border: 2px solid #907650;
    color: #907650;
    padding: 5px 15px;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 5px;
    margin-top: 10px;
  }

  .button-stepper:hover {
    text-transform: uppercase;
    background-color: #907650 !important;
    border-color: #907650;
    color: white;
    padding: 5px 15px;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 5px;
    margin-top: 10px;
  }

  .reservation_header {
    text-align: center;
  }

  .reservation_text {
    text-align: center;
  }

  .reservation_hint {
    text-align: center;
  }

  .nav-menu.active {
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item .active {
    color: white;
    border: none;
  }

  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: white;
  }

}
.sidebar-left_wrapper .sidebar {
  padding: 50px 12% 50px 22%;
}
h5 {
  font-size: 1.2rem;
  padding-bottom: 0;
  text-transform: uppercase;
  font-weight: 400 !important;
}

/********* MAIN PAGES *********/


/********* MEDIA QUERY *********/
@media (min-width: 1200px) {
  .widget-booking .widget-booking__dates {
    padding: 1em 2em 1.5em;
  }
}
@media (min-width: 992px) {
  .widget-booking .widget-booking__dates {
    overflow: hidden;
  }
}
@media (min-width: 992px) {
  .widget-booking .widget-booking__dates {
    display: block;
    padding: 1em 1em 1.5em;
  }
}
@media (min-width: 1200px) {
  .widget-booking .widget-booking__date-block {
    padding: 1em 1.5em;
  }
}
@media (min-width: 992px) {
  .widget-booking .widget-booking__date-block--checkin {
    float: left;
  }
}
@media (min-width: 992px) {
  .widget-booking .widget-booking__date-block {
    padding: 0.5em 1em;
    border: 1px solid #967a50;
  }
}
/********* MEDIA QUERY *********/

/* BOOKING PAGES CSS */

.widget-booking .widget-booking__dates {
  display: inline-block;
  letter-spacing: 1px;
  margin-bottom: 0;
  padding: 1em 2em 1.5em;
  text-transform: uppercase;
}
.widget-booking .widget-booking__date-block {
  border-color: #967a50;
}


.widget-booking .widget-booking__date-block--checkin,
.widget-booking .widget-booking__date-block--checkout {
  display: inline-block;
  text-align: center;
}
.widget-booking .widget-booking__date-block {
  background-color: #fff;
  padding: 0.75em 1.5em;
}
.widget-booking .widget-booking__date-label {
  font-size: 9px;
  color: #999;
  font-weight: 700;
  margin-bottom: 1em;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.widget-booking .widget-booking__date-block--checkin,
.widget-booking .widget-booking__date-block--checkout {
  display: inline-block;
  text-align: center;
}
.widget-booking .widget-booking__date-label {
  font-size: 9px;
  color: #999;
  font-weight: 700;
  margin-bottom: 1em;
}
.widget-booking .widget-booking__month-year {
  font-size: 10px;
  font-weight: 600;
  display: block;
}
.secondary,
.offer_price,
.offer_price span,
.offer_price span:not(.amount),
.event_date_wrapper,
.event2-carousel .event-date-wrapper,
.event_date_zone,
.post_date_wrapper,
.author-meta .name,
.comment-body .author-name,
.comment-body .reply i,
.calluna-toggle .calluna-toggle-trigger.active,
.calluna-pricing .calluna-pricing-cost,
.calluna-pricing .calluna-pricing-per,
.calluna-testimonial-author,
.icon-clock,
.simple-weather i,
.pre,
.modal-price,
.single-room .booking-price_wrapper .amount,
.room-loop__list li.room .button--view-room-details,
table.hotelier-table .reservation-table__room-link:hover,
table.hotelier-table .view-price-breakdown:hover,
.single-room .room-meta-wrapper a:hover,
.single-room .room__rates .rate__conditions ul,
.hotelier-listing .selected-nights:before,
form.form--listing .room__only-x-left,
form.form--listing .room__more-link:hover,
form.form--listing .room__gallery-link:hover,
form.form--listing .room__conditions ul,
form.form--listing .rate__conditions ul,
form.form--listing .room__max-guests .max:after,
ul.reservation-details__list strong,
.widget-rooms-filter li:hover a,
.widget-rooms-filter li:hover a:before,
.widget-rooms-filter li.widget-rooms-filter__group-item--chosen a:before,
.widget-rooms-filter li.widget-rooms-filter__group-item--chosen a:hover:before,
.widget-booking .widget-booking__day,
.widget-booking .widget-booking__rooms-list a:hover,
.widget-rooms .widget-rooms__name:hover,
#hotelier-datepicker .datepicker-input-select-wrapper:before {
  color: #967a50;
}
.widget-booking .widget-booking__day {
  font-size: 26px;
  font-weight: 500;
  color: #967a50;
}
.widget-booking .widget-booking__day-name,
.widget-booking .widget-booking__month-year {
  font-size: 10px;
  font-weight: 600;
  display: block;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.widget-booking .widget-booking__date-block--checkin,
.widget-booking .widget-booking__date-block--checkout {
  display: inline-block;
  text-align: center;
}

.sidebar-left_wrapper .sidebar {
  padding: 50px 12% 50px 22%;
}
.sidebarcontainer{
  position: sticky;
  top: 15px;
}
.sidebarcontaineropacity{
  opacity: 0.4;
    position: sticky;
    top: 15px;
}
.sidebar_wrapper{
  position: relative;
}
.sidebar {
  margin: auto;
  padding: 10px;
  width: 70%;
  border: 1px solid #967a50;
  background: #EAE9EE;
}
.widget-rooms-filter .widget-rooms-filter__group-label {
  background-color: #967a50;
}

.widget-rooms-filter .widget-rooms-filter__group-label {
  font-size: 10px;
  background-color: #967a50;
  color: #fff;
  display: block;
  font-family: Lato, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 1em;
  padding: 0.5em 1em;
  text-transform: uppercase;
  cursor: pointer;
}
a:hover,
a:focus,
.primary,
.headline,
.page-header,
.sidebar .widget:not(.widget--hotelier) ul li a:hover,
.sidebar .widget:not(.widget--hotelier) ul li a:focus,
.prev-post a:hover,
.prev-post a:focus,
.next-post a:hover,
.next-post a:focus,
.more-link:focus,
.more-link:hover,
blockquote p,
ul.post-sharing label,
.image-row span,
.simple-weather em,
.comment-form,
.time,
.calluna-toggle .calluna-toggle-trigger,
.calluna-testimonial-author span,
.calluna-callout-caption h1,
.calluna-callout-caption h4,
.calluna-callout-caption h5,
.calluna-shortcodes h1.calluna-heading,
.calluna-shortcodes h4.calluna-heading,
.calluna-shortcodes h5.calluna-heading,
.event2-carousel .event-title-wrapper .event-title h3,
.event2-carousel .event-title-wrapper .event-title h3 a,
.woocommerce .woocommerce-info:before,
.room_name a,
.price-text,
.hotelier-pagination ul .page-numbers,
.room__deposit,
.rate__deposit,
.room-loop__list li.room .room__price .amount,
table.hotelier-table .reservation-table__room-link,
table.hotelier-table .view-price-breakdown,
table.hotelier-table
  .price-breakdown-wrapper
  table
  .price-breakdown__day--heading,
table.hotelier-table
  .price-breakdown-wrapper
  table
  .price-breakdown__cost--heading,
.single-room .room__gallery-link,
.single-room .room-meta-wrapper,
.single-room .room-available-rates a,
form.form--listing .room__more-link,
form.form--listing .room__gallery-link,
.widget-rooms-filter a,
.widget-booking .widget-booking__rooms-list a,
.widget-booking .amount,
.widget-rooms .widget-rooms__name,
.widget-rooms .widget-rooms__price .amount {
  color: #7b3246;
}
.widget-rooms-filter a {
  font-size: 10px;
  color: #7b3246;
  display: block;
  font-family: Lato, sans-serif;
  letter-spacing: 1px;
  margin-bottom: 0.3em;
  padding: 0.4em 0 0.4em 3em;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
}
.widget-rooms-filter a:before {
  font-size: 16px;
  color: #d2d2d2;
  content: "\f0c8";
  font-family: FontAwesome;
  margin-right: 1em;
  position: absolute;
  left: 2px;
  top: -2px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
ul {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}
.availibility {
  margin-left: 20px;
}
.pad-5 {
  background-color: white;
  padding: 50px;
}
.flatpickr-2 {
  height: 40px;
  border: 1px solid #907650;
  border-radius: 4px;
  padding: 10px;
}
.selected-nights {
  margin-top: 20px;
  font-size: 12px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  border-bottom: 1px solid #f1f2f2;
}
.selected-nights:before {
  font-size: 18px;
  color: #967a50;
  content: "\f14a";
  font-family: FontAwesome;
  margin-right: 0.75em;
  position: relative;
  top: 2px;
}
.button-stepper-availibity {
  margin: 0px !important;
  text-transform: uppercase;
  background-color: #907650 !important;
  border: 2px solid #907650 !important;
  padding: 7px 10px;
  font-size: 13px;
  letter-spacing: 2px;
  color: white;
  width: 130px;
}

.button-stepper-availibity:hover {
  text-transform: uppercase;
  background-color: #907650 !important;
  border: 2px solid #907650 !important;
  padding: 7px 10px;
  font-size: 13px;
  letter-spacing: 2px;
  color: white;
}
.button-stepper-showrates {
  text-transform: uppercase;
  background-color: #907650 !important;
  border: 2px solid #907650 !important;
  padding: 7px 10px;
  font-size: 13px;
  letter-spacing: 2px;
  color: white;
  width: 130px;
  margin-top: 20px;
}

.button-stepper-showrates:hover {
  text-transform: uppercase;
  background-color: #907650 !important;
  border: 2px solid #907650 !important;
  padding: 7px 10px;
  font-size: 13px;
  letter-spacing: 2px;
  color: white;
  margin-top: 20px;
}
.button-stepper-showrates:focus {
  outline:unset !important;
}
.button-stepper-availibity:focus {
  outline:unset !important;
}
h4{
  font-weight: 400;
}
.button-stepper-Reserve {
  background: #907650;
  border: 2px solid #907650;
  color: white;
  padding: 7px 20px;
  font-size: 16px;
  letter-spacing: 2px;
  font-family: 'Raleway', sans-serif;
  margin-right: 0px;
  margin-bottom: 65px;
}
.button-stepper-Reserve:hover {
  font-family: 'Raleway', sans-serif;
  background-color: #907650;
  border: 2px solid #907650;
  color: white;
  padding: 7px 20px;
  font-size: 16px;
  letter-spacing: 2px;
}
.rooms-page{
    background: white;
}
.widget-stay{
  font-weight: 400;
  font-size: 15px;
}
.card-rooms {
  margin-left: 0px;
  margin-top: 30px;
}
.card-notes{
  margin-left: 0px;
}
.notes-list{
  list-style: disc;
  margin-left: 15px;
  font-weight: 400;
  font-size: 13px;
}
.title-edit{
  margin-bottom: 0px;
  font-size: 1.2rem;
  padding-bottom: 0;
  text-transform: none !important;
  font-weight: 400 !important;
  font-family: 'Playfair Display', serif;
}
.title-edit-p{
  margin-top: 5px;
  font-size: 0.9rem;
  padding-bottom: 0;
  font-weight: 400 !important;
}
.rate__content{
  margin-left: 26%;
}
.manage-rooms-selection{
  font-weight: 400;
}
.managerooms{
    font-size: 18px !important;
    border: 1px solid #7b3246;
    margin-left: 10px;
    padding: 10px;
}
.fa-pen-square:before {
  font-size: 25px;
}
.room__price--listing{
font-weight: 400;
}
.room {
  background: white;
  padding: 10px;
  border: 1px solid #967a50;
}
.facilities{
  font-weight: 400;
}
.facilities-icon i{
  font-size: 10px;
  color:#967a50;
}
.facilities-icon p{
  margin-left: 5px;
}
.room__gallery-link--listing {
  display: inline-block;
  font-size: 10px;
  color: #7b3246;
  font-family: Lato, sans-serif;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  margin-top: 6px;
}
.widget-title {
  color: #7b3246;
  font-size: 1.6rem;
  font-weight: 400;
  font-family: 'Playfair Display', serif;
  text-transform: none;
  line-height: 1.3;
}
.room__description {
  font-size: 15px;
  color: #999;
  line-height: 2rem;
}
.room__description--listing p {
  font-size: 16px;
  font-weight: 400;
  color: #7b3246;
  line-height: 19px;
}
.fa-users{
  color: #967a50;
  font-size: 14px;
}
.room__more-link {
  font-size: 10px;
  color: #7b3246;
  font-family: Lato, sans-serif;
  text-transform: uppercase;
  text-decoration: underline;
}

.facility-grp p {
  background: #f1f2f2;
  color: white;
  padding: 2px 5px 5px 9px;
  margin-left: 2px;
  color: #7b3246;
}
.facilities p{
  font-size: 13px;
}
.room__max-guests .room__max-guests-label {
  font-weight: 400;
  font-size: 12px;
}
.room__max-guests .max.max2:after {
  content: "\f007\f007";
}
.room__max-guests .max:after {
  color: #967a50;
  content: "\f007";
  float: left;
  font-family: FontAwesome;
  letter-spacing: 3px;
  text-indent: 0;
}
.rate__price,
form.form--listing .room__price {
  font-size: 14px;
  color: #242424;
  display: block;
}
.amount {
  font-style: normal;
  font-weight: 600;
}
.yourreservationdet {
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-size: 15px;
}
.reservation-room-total{
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0.8rem;
  font-size: 15px;
}
.reservation-room-nbr{
  font-weight: bold;
}
.room__price-description {
  font-size: 12px;
  font-style: normal;
  display: block;
  margin-bottom: 1.5em;
  font-size: 15px;
  color: #999;
  line-height: 2rem;
}
.notes{
  padding: 25px 10px 25px 10px;
  border: 1px solid #967a50;
}
.notes p{
  padding: 25px 10px 25px 10px;
  margin-top: 10px;
}
.widget-booking__cart-total {
  overflow: hidden;
  text-transform: uppercase;
}
strong {
  font-weight: 700;
}
#loader.loader-style {
  border-bottom-color: #967a50;
}
h3 {
  font-size: 2.125rem;
  line-height: 2.625rem;
  letter-spacing: 0.1em;
  font-weight: 400;
  color: #7b3246;
  text-transform: uppercase;
  margin-bottom: 20px;
}
label {
  font-size: 1.25rem;
  letter-spacing: 0.072em;
  font-weight: 500;
  margin-bottom: 15px;
  color: #444;
  margin-top: 15px;
}
input,
textarea {
  background-color: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 0;
  color: #444;
}
input,
textarea {
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d2d2d2;
  height: 48px;
  font-size: 1rem;
  color: #444;
  box-shadow: none;
  border-radius: 0;
}
textarea {
  min-height: 150px;
}
.form-row__description {
  font-size: 12px;
  display: block;
  margin-top: 20px;
}
.dropdown-login {
  width: 150px;

  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  color: white;
  border-left: 1px solid white;
  height: 100%;
}

.dropdown-login-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-login-body {
  padding: 5px;
  border-top: 1px solid #e5e8ec;
  display: none;
}
.Signin{
  color:black;
}
.dropdown-login-body.open {
  z-index: 9999;
  position: absolute;
  box-shadow: 0px -2px 9px -2px rgba(0,0,0,0.79);
  -webkit-box-shadow: 0px -2px 9px -2px rgba(0,0,0,0.79);
  -moz-box-shadow: 0px -2px 9px -2px rgba(0,0,0,0.79);
  width: 100%;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  color: black;
  display: block;
}
.dropdown-login-item {
  padding: 10px;
}
.sidebarcontainer .spinner-border{
   position: absolute;
    left: 50%;
    top: 50%;
    right: 50%;
    bottom: 50%;
    color: #7b3246;
}
.taxandfees{
  border-bottom: 1px solid #a19bbb;
  padding-bottom: 15px;
}
.sidebarcontaineropacity .spinner-border{
  position: absolute;
  left: 45%;
  top: 40%;
  right: 40%;
  bottom: 50%;
  color: #7b3246;
  display: inline-block;
  width: 4rem;
  height: 4rem;
}
.dropdown-login-item:hover {
  cursor: pointer;
}

.dropdown-login-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.dropdown-login-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}
.form--login .form__title {
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 18px;
  width: 100%;
}
.form .form__meta {
  display: block;
  text-align: right;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
}
.form--login .field {
  background:transparent;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #242424;
  font-weight: 700;
  letter-spacing: 0.03em;
  color: #242424;
  padding: 12px 0;
  font-size: 13px;
}
.form--login .form__btn {
  margin-left: 0px;
  margin-top: 20px;
  display: block;
  margin-bottom: 20px;
  width: 100%;
  margin-bottom: 10px;
  line-height: 43px;
  padding: 0 15px;
  background-color: #816a35 !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.35px;
  font-weight: 700;
  -webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  border: 1px solid #816a35;
}
.loginicons i{
  color: #816a35;
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 8px;
}
.loginicons div{
  width: 100px;
  margin-right: 10px;
}
.loginicons p{
  text-align: center;
  font-size: 12px;
}
.loginicons{
  margin-top: 36px;
  display: flex;
  font-weight: 400;
}
.btn_bepartner{
  position: absolute;
  bottom: 23px;
}
.form--login .form__actions a {
  font-size: 12px;
  font-weight: 500;
  color: #242424;
  text-decoration: underline;
}
.login-row {
  z-index: 99999;
  padding: 70px;
  max-width: 1106px !important;
}
.form--login .form__title {
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 18px;
  color: #7b3246;
}
.form__label {
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 15px;
  color: #7b3246;
}
.form .form__meta {
  display: block;
  text-align: right;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.02em;
  margin-top: 30px;
}
.border-l {
  border-right: 1px solid #7b3246;
}
.section__subtitle {
  font-size: 18px;
  margin-bottom: 10px;
}
.section__subtitle {
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 35px;
}
.section__entry p {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 400;
  color: #403a60;
  letter-spacing: 0.02em;
}
.link {
  position: relative;
  font-size: 14px;
  border: 0 none;
  background: none;
  outline: none;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #403a60;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.fa-times {
  float: right;
  cursor: pointer;
  right: 0px;
  position: absolute;
  top: 0px;
}
.dropbtn {
  z-index: 0;
}

@media only screen and (max-width: 600px) {
  .mobile-d {
    display: none !important;
  }

  .booking-button_wrapper{
    margin-top:0px !important;
  }
  .button-stepper{
    margin-top: 0px;
  }
  .button-stepper:hover{
    margin-top: 0px;
  }
  .App{
    align-items: baseline;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 601px) {
  .closev2{
    display: none;
  }
}
.footer p {
  font-size: 12px;
  cursor: pointer;
}
.fa-rev{
  font-size: 16px;
}
.container-guest {
  position: relative;
  display: inline-block;
}
.button-guest {
  padding: 0;
  width: 50px;
  border: 0;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  outline: 0;
  font-size: 40px;
}
.dropdown-guest {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400 !important;
  position: fixed !important;
  top: 100%;
  right: 15px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background: white;
  padding: 20px;
  top: 0px !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 9999 !important;
  height: 100% !important;
}
.room-nbr{
  margin-bottom: 0px;
  padding: 8px 12px 0px 12px;
  color: #7b3246;
  letter-spacing: 0.1rem;
  justify-content: start;
  font-family: 'Playfair Display', serif;
  font-size: 1.3rem;
  font-weight: 500;
}
.ul-guest {
  list-style: none;
  padding: 0;
  margin: 0;
}
.li-guest {
  font-weight: 400 !important;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
  color: #7b3246;
  display: flex;
  padding: 0px 12px;
  align-items: center;
  
}


.add-minusrow{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 14px;
  border:1px solid #7b3246;
  margin-right: 10px;
  margin-left: 10px;
}
.guests-title{
  font-family: 'Playfair';
  font-size: 1rem;
  font-weight: 500;
  width:110px;
}
.guests-title-rec{
  font-size: 11px;

}
.sb-group-field-has-error {
  border-color: #c00;
}

.sb-group-field-has-error {
  border-color: #c00;
}
.sb-group__children__field select {
  margin: 16px 0 0 16px;
}
.sb-group-field-has-error {
  border: 1px solid #0071c2;
  color: #333;
  margin-left: auto;
  padding: 8px 2px 8px 8px;
  border-radius: 2px;
  margin:4px;
  background-position: right 8px top 50%;
  min-width: 48px;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
  color: #7b3246;
  
}
.ul-guest{
  display: flex;
  justify-content: center;
}
.addrow{
  text-transform: uppercase;
  background-color: #f1f2f2 !important;
  border: 2px solid #907650;
  color: #907650;
  padding: 7px 20px;
  font-size: 11px;
  letter-spacing: 2px;
  border-radius: 5px;
  margin: 0px;
  margin-bottom: 5px;
  margin-right: 15px;
}
.gg-trash {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 10px;
  height: 12px;
  border: 2px solid transparent;
  box-shadow:
      0 0 0 2px,
      inset -2px 0 0,
      inset 2px 0 0;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  margin-top: 4px
}
.gg-trash::after,
.gg-trash::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute
}
.gg-trash::after {
  background: currentColor;
  border-radius: 3px;
  width: 16px;
  height: 2px;
  top: -4px;
  left: -5px
}
.gg-trash::before {
  width: 10px;
  height: 4px;
  border: 2px solid;
  border-bottom: transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  top: -7px;
  left: -2px
}
.d-none{
  display: none !important;
}
.addrow1-none{
  display: none !important;
}
.rooms-sh-nbr{
  font-weight: 500;
  margin: 0 0 0px;
  text-align: center;
  color: #7b3246;
  font-size: 17px;
  letter-spacing: 0.1rem;
  color: #7b3246;
  font-family: Lato, sans-serif;
  font-weight: 300 !important;
  font-weight: bold;
}
.nbrs-sh{
  font-weight: 500;
  margin: 0 0 14px;
  text-align: center;
  color: #7b3246;
  font-size: 10px;
  letter-spacing: 0.1rem;
  color: #7b3246;
  text-align: left !important;
  font-family: Lato, sans-serif;
  font-weight: bold;
}
.devider{
  border:1px solid #f1f2f2;
}
.datef{
  min-height: 211px;
  padding: 16px 0 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: none repeat scroll 0 0 #fff;
  color: #2f373b;
  float: left;
  padding: 16px 0 26px;
  position: relative;
  width: 75%;
  border: 2px solid transparent;
}
.datef .border{
  margin-top: 0px !important;
}
.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.managerooms{
  cursor: pointer;
}
.loading div {
  position: absolute;
  background: #7b3246;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -.7s;
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
.preloader-container{
  height: 100vh;
  display: flex;
  justify-content:center;
  align-items: center;
}
.main-page {
  animation: fade 1.5s;
}
@-webkit-keyframes fade {
  from {opacity: 0;}
  to {opacity: 1;}
} 

@keyframes fade {
  from {opacity: 0;}
  to {opacity: 1;}
}

.dateField{
  cursor: pointer;
}

.room{
  cursor: pointer;
}
.showrates{
  border: 0;
  font-size: 13px;
}
.showrates:hover{
  border: 0;
  font-size: 13px;
}
.rate__deposit, .room__deposit {
  font-size: 10px;
  background-color: #f1f2f2;
  border-radius: 120px;
  color: #7b3246;
  display: inline-block;
  font-family: Lato,sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}
.rate__deposit, .room__deposit {
  font-size: 10px;
  background-color: #f1f2f2;
  border-radius: 120px;
  color: #7b3246;
  display: inline-block;
  font-family: Lato,sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.room__rate--listing {
  margin-top: 15px;
  border-top: 1px solid #f1f2f2;
  padding: 20px;
  font-weight: 400;
}
.policy{
  color: #907650 !important;
  font-size: 13px !important;
  margin-bottom: 1px;
}
.max.max2:after {
  content: "\f007\f007";
}
.main-page-none{
  display: none !important;
}
.rate__room{
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  color: #7b3246;
}
.promotion-description{
  color: rgb(168, 20, 20);
}
.promotion-description div{
  display: flex;
  font-weight: 400;
  margin-top: 5px;
}
.promotion-description p{
  margin-bottom: 0px;
 }
.modal-dialog-centered .modal-content{
  background-color: transparent;
  color: white !important;
  border:none !important;
  font-family: Raleway;
}
.modal-sm{
  max-width: 1224px !important;
}
.room-selection-modal{
  width: 320px;
  padding: 15px;
  border: 1px solid white;
  background-color: white;
  color: black;
  border-radius: 10px;
}
.modal-backdrop.show {
  opacity: .8;
}
.current-room{
  position: absolute;
  top: -24px;
}
.modal-dialog-centered .modal-content{
  box-shadow: none !important;
}
.modal-rooms{
  padding:50px;
  background: #EAE9EE;
}
.sidebar-rooms-modal{
  margin: auto;
  padding: 10px;
  border: 1px solid #967a50;
  background: #EAE9EE;
  color: black;
  font-weight: 400;
}
.cursor-pointer{
  cursor: pointer;
}
.modal-rooms .modal-body{
  flex:unset !important;
}
.btn-primary:not([disabled]):not(.disabled):active{
  background-color: unset !important; 
   box-shadow: unset !important;
}
.invalid{
  border:1px solid red !important;
}
.fa-user{
  color: #967a50;
  margin-left: 10px;
}
.add-to-cart-selected {
  font-size: 9px;
  display: block;
}
.yourreservation{
  padding-bottom: 14px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: yellow;
}
.disabled-reserve-btn{
  opacity: 0.4;
  cursor: no-drop !important;
}
.srv-validation-message{
  color: red;
  font-size: 13px;
  margin-top: 4px;
  font-weight: 400;
}
.form__controls :focus-visible{
  outline:none !important;
}
.sectionguest-header__title{
  font-family: 'Playfair Display', serif;
  text-transform: unset;
  letter-spacing: unset;
  margin-bottom: 0;
}
.sectionguest-title__title{
  font-family: 'Playfair Display', serif;
  text-transform: unset;
  letter-spacing: unset;
  margin-bottom: 0;
  font-size: 25px;
  margin-top: 15px;
}
.sectionguest-title{
  font-family: 'Playfair Display', serif;
  text-transform: unset;
  letter-spacing: unset;
  margin-bottom: 0;
  font-size: 17px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.fa-arrow-left{
  font-size: 25px;
  color: #7b3246;
}
.card-form{
  border: 1px solid #967a50;
  display: flex;
  flex-direction: column;
  padding: 0px 5px 0px 5px;
}
.form-input-guest{
  border: none;
  padding: 0px;
  height: 20px;
}
.form-input-label{
  font-size: 15px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.sidebar-guests{
  width: 100% !important;
}
.menu-flags-guest{
  font-weight: 400;
}
.menu-flags-guest-btn{
  border: 1px solid #967a50;
  font-weight: 400;
}
.special_request{
  border: 1px solid #967a50;
}
.card-form-payment{
  padding:15px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.card-form-payment h5{
  font-weight: 600 !important;
  text-transform: none;
  color: #7b3246;
  font-size: 16px;
  margin-bottom: 5px;

}
.card-form-payment p{
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 3px;
  margin-top: 3px;
}
.payment-cards p{
  font-weight: 600;
  font-size: 18px;
  font-family: "Roboto";
  color: #7b3246;
}
.payment-cards input{
  height: 37px;
}
.card-form-secure{
  padding:15px;
}
.card-form-secure p{
  font-weight: 400;
  font-size: 9px;
}
.card-form-secure h5{
  font-weight: 600 !important;
  text-transform: none;
  color: #7b3246;
  font-size: 16px;
  margin-bottom: 5px;
}
.cancellation-policytxt{
  font-size: 13px !important;
  font-weight: 400;
}
.Credit{
  color: white;
  font-family: 'Roboto';
}
.card-credit label{
  color: white !important;
}
.form-edit-user form{
  width: 60%;
  
}
.form-edit-user{
display: flex;
justify-content: center;
background: white;
}
.form-edit-user label{
  color: black !important;
}
.btn-action{
  margin-left: 0px;
  margin-top: 20px;
  display: block;
  margin-bottom: 20px;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 15px;
  background-color: #816a35 !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.35px;
  font-weight: 700;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  border: 1px solid #816a35;
}
.rdt_Pagination{
  font-weight: 700 !important;
}
.modal-markup .modal-content{
  background: white;
  border-radius: 10px;
  color:black;
  padding: 20px;
}
.modal-markup .modal-content h4{
  color: black;
}
.modal-markup .modal-content p{
  color: black;
  font-weight: 400;
  font-size: 13px;
}
.btn-action-markup{
  margin-left: 0px;
  margin-top: 20px;
  display: block;
  height: 43px;
  margin-bottom: 20px;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 15px;
  background-color: #816a35 !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.35px;
  font-weight: 700;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  border: 1px solid #816a35;
}
.no-rooms-availibility{
  font-weight: 400;
  text-align: center;
}
/******* END BOOKING PAGES CSS *********/
.resetpasswordbtn{
  color: red;
  font-weight: 400;
  text-align: right;
  font-size: 12px;
  cursor: pointer;
}
.resetpassff{
  width: 700px;
}
input:focus-visible {
  outline: unset;
}
.credit{
  font-weight: 400;
}
.credit strong{
  font-family: monospace;
}
.flat-pickrcredit{
  width: 280px;
}
.datatable-ff{
  font-weight: 400 !important;
}

.btnsearch{
background: white !important;
border-radius: 5px;
box-shadow: none;
width: 150px;
opacity: 1 !important;
margin-top: 0px;
font-family: 'Raleway', sans-serif;
border:none;
}

.btnsearch span{
  color: #7b3246 !important;
}
.nightsNbr{
  font-weight: 600;
}
.retry{
  color: white;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(139, 133, 133, 0.02) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::placeholder {
  color: #aab7c4;
}


.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
.leftbtnstripe{
  display:flex;
  justify-content: space-between;
}
.clic2paybtn{
  display:flex;
  justify-content: space-between;
}